<template>
  <div class="home">
    <div class="priority-grid">
      <PriorityCard
        v-for="priority in priorities"
        :key="priority.id"
        :priority="priority"
        :settings="settings"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PriorityCard from '@/components/PriorityCard.vue';

// Components
// import HelloWorld from '../components/HelloWorld.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    // HelloWorld,
    PriorityCard
  },

  data() {
    return {
      priorities: [],
      settings: {}
    };
  },
  mounted() {
    this.fetchPriorities();
  },
  methods: {
    async fetchPriorities() {
      try {
        const prioritiesResponse = await this.$http.get('https://cdcu.ng/api/method/cdcu.v3.api.get_priorities', {
          headers: {
            'Authorization': 'Token ef9a7a12e4de5d8:abe332d837de9b7',
            'Content-Type': 'application/json'
          }
        });
        this.priorities = prioritiesResponse.data.message.data;
        const settingsResponse = await this.$http.get('https://cdcu.ng/api/method/cdcu.v3.api.get_settings', {
          headers: {
            'Authorization': 'Token ef9a7a12e4de5d8:abe332d837de9b7',
            'Content-Type': 'application/json'
          }
        });
        this.settings = settingsResponse.data.message.data[0]; // Assuming we only have one settings object
      } catch (error) {
        console.error(error);
      }
    }
  }
});
</script>

<style scoped>
.priority-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  /* Add more styles for responsiveness */
}

@media (max-width: 768px) {
  .priority-grid {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }
}
</style>
