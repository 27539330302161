<template>
    <div class="priority-card">
      <div class="priority-number">{{ priority.number }}</div>
      <div class="priority-content">
        <h2>{{ priority.title }}</h2>
        <p>{{ priority.sub_title }}</p>
      </div>
      <div class="priority-icon">
        <img :src="priority.icon_url" alt="Priority Icon" width = "100px">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      priority: Object,
      settings: Object
    }
  }
  </script>
  
  <style scoped>
.priority-card {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px;
}

.priority-number {
  background-color: #4CAF50; /* Adjust the color to match your theme */
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.priority-content {
  flex: 1;
}

.priority-content h2 {
  font-size: 1em;
  margin: 0;
  color: #333; /* Adjust the color to match your theme */
}

.priority-content p {
  font-size: 0.8em;
  color: #666; /* Adjust the color to match your theme */
  margin: 5px 0;
}

.priority-icon img {
  transform: scale(0.4); /* Adjust if necessary */
  margin-left: 10px;
}
</style>

